import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';

const privacyPolicy = (): React.ReactElement => {
  return (
    <Layout>
      <div id="privacyPolicy">
        <h1>Política de Privacidade</h1>
        <p>
          A sua privacidade e a protecção dos seus dados pessoais são pilares essenciais para a
          WALKING DIAGONAL LDA. Esta declaração de privacidade irá informá-lo sobre a forma como
          usamos os seus dados pessoais, os direitos de que goza e os mecanismos legais que tem ao
          seu dispor. Queira por favor ler atentamente esta declaração de privacidade antes de
          utilizar os nossos Serviços.
        </p>
        <p>Nesta Política de Privacidade:</p>
        <p>
          <strong>Serviços</strong> refere-se a quaisquer produtos, serviços, conteúdos,
          funcionalidades, tecnologias ou funções e todos os websites, aplicações e serviços
          relacionados que lhe são oferecidos por nós.
        </p>
        <p>
          <strong>Plataforma</strong> refere-se ao website e aplicação móvel através dos quais lhe
          oferecemos os nossos Serviços.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>Quem é o responsável pela recolha e tratamento dos dados?</strong>
          </li>
        </ol>
        <p>
          A WALKING DIAGONAL LDA é a entidade responsável pelo tratamento dos seus dados pessoais,
          fornecidos pelo respetivo titular, bem como dos demais dados pessoais recolhidos em
          qualquer outro suporte, incluindo os respeitantes à utilização dos Serviços prestados pela
          WALKING DIAGONAL LDA e os resultantes da utilização da APP ou simuladores disponibilizados
          para pesquisa, marcação e gestão dos serviços e informação gerada com base nessa
          utilização (doravante "<strong>Dados Pessoais</strong>"), os quais serão tratados para as
          finalidades enumeradas no ponto 4, abaixo.
        </p>
        <p>
          A WALKING DIAGONAL LDA … é uma sociedade de responsabilidade […]. com o número único de
          matrícula e de pessoa coletiva, 515901105, com sede na Rua de São Bernardo nº22, 1200-825
          Lisboa.
        </p>
        <p>&nbsp;</p>
        <ol start={2}>
          <li>
            <strong>Quais os contactos do responsável pelo tratamento? </strong>
          </li>
        </ol>
        {/* <p>&nbsp;</p> */}
        <p>
          O Responsável pelo Tratamento de Dados Pessoais pode ser contactado (designadamente para
          qualquer informação adicional ou para exercer os seus direitos), por escrito, para os
          seguintes endereços:
        </p>
        <p>&nbsp;</p>
        <p>- [Rua de São Bernardo nº22, 1200-825 Lisboa];</p>
        <p>- [info@suopapp.com]; ou </p>
        <p>- Secção de ajuda da nossa Aplicação.</p>
        <p>&nbsp;</p>
        <ol start={3}>
          <li>
            <strong>&nbsp;</strong>
            <strong>O que são dados pessoais?</strong>
          </li>
        </ol>
        <p>
          Dados pessoais são qualquer informação, de qualquer natureza e independentemente do
          respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou
          identificável. É considerada identificável a pessoa que possa ser identificada direta ou
          indiretamente, designadamente por referência a um número de identificação ou a um ou mais
          elementos específicos da sua identidade física, fisiológica, psíquica, económica, cultural
          ou social.
        </p>
        <p>&nbsp;</p>
        <ol start={4}>
          <li>
            <strong>Que dados pessoais são recolhidos e tratados?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <ol>
              <li>
                <strong>Dados fornecidos por interação direta </strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>- Registo e outras informações de conta</strong>
        </p>
        <p>
          Se decidir subscrever os nossos Serviços, em concreto o nosso <em>website </em>e App,
          ser-lhe-á pedido que adicione o seu <strong>produto/artigo</strong>, que é um elemento
          obrigatório do anúncio. Ao disponibilizar o seu produto/artigo deverá adicionar os
          seguintes dados que serão acessíveis ao público: uma fotografia do mesmo, o título do
          artigo, uma descrição e uma categoria. Deverá igualmente atribuir um valor monetário ao
          produto/artigo ao qual apenas a WALKING DIAGONAL LDA terá acesso. Sem estes dados, não
          conseguiremos prestar os Serviços.
        </p>
        <p>
          Quando se regista na nossa App, ser-lhe-á pedido que forneça, obrigatoriamente, o seu
          endereço de email. Em adição e facultativamente, poderá também fornecer alguns dados que
          serão de acesso público, a saber: o seu nome, apelido, a sua cidade, uma descrição que
          constará do seu perfil (<em>Nickname</em>) e uma fotografia de perfil. Em adição,
          ser-lhe-ão solicitados o seu NIF, data de nascimento e número de telemóvel, dados esses a
          que apenas a WALKING DIAGONAL LDA terá acesso, para efeitos de segmentação.
        </p>
        <p>
          Em alternativa a este registo, o utilizador poderá registar-se via Facebook, passando a
          WALKING DIAGONAL LDA a tratar os dados pessoais do utilizador, que por essa via lhe forem
          transmitidos.
        </p>
        <p>
          <strong>- Comunicação através da funcionalidade de chat na nossa Plataforma</strong>
        </p>
        <p>
          Ao usar a nossa funcionalidade de <em>chat</em> para comunicar com outros utilizadores,
          recolhemos a informação que escolha fornecer a outros utilizadores através da mesma. Todas
          as mensagens trocadas entre utilizadores são mantidas numa base de dados, com elevados
          níveis de segurança, incluindo encriptação, de acesso altamente restrito (se e quando
          necessário), por forma a melhor proteger a utilização da Plataforma por parte dos seus
          utilizadores, designadamente para a filtragem e apreensão de mensagens de <em>spam</em> e
          prevenção de ataques aos sistemas de suporte.&nbsp;
        </p>
        <p>&nbsp;</p>

        <ol>
          <li>
            <ol start={2}>
              <li>
                <strong>Dados que recolhemos automaticamente quando usa os nossos Serviços</strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Quando interage com a nossa App, ou usa os nossos Serviços, recolhemos automaticamente as
          seguintes informações sobre si:
        </p>
        <ol>
          <li>
            <strong>Informações sobre o seu dispositivo: </strong>como por exemplo, a versão de
            sistema operativo ou identificadores únicos, associando esse dispositivo aos
            identificadores de dispositivo da sua conta WALKING DIAGONAL LDA.
          </li>
          <li>
            <strong>Informações de localização: </strong>dependendo das permissões no seu
            dispositivo, se publicar um artigo no nosso <em>website</em>, iremos recolher e
            processar automaticamente a informação acerca da sua localização atual através de
            endereço de IP, GPS, pontos de acesso <em>Wi-fi</em> e torres de dados móveis.
          </li>
          <li>
            <strong>Dados de utilizador e de Registo:</strong> detalhes técnicos, incluindo o
            Protocolo de Internet (IP) do seu dispositivo, fuso horário e sistema operativo. Também
            iremos armazenar a sua informação de início de sessão (data de registo, data da última
            alteração de palavra-passe, data do último início de sessão realizado com sucesso), o
            tipo e a versão do seu navegador.
          </li>
          <li>
            <strong>Dados de clickstream:</strong> recolhemos informação acerca da sua atividade na
            nossa App, incluindo os <em>websites</em> que usou para aceder à nossa Plataforma,
            registo de data e hora de cada visita, pesquisas realizadas, listagens ou
            <em>banners</em> de publicidade a que tenha acedido, a sua interação com esses anúncios
            de publicidade ou listagens, duração da sua visita e a ordem em que acedeu ao conteúdo
            na nossa App.
          </li>
          <li>
            <strong>Cookies e Tecnologias Similares:</strong> usamos cookies para gerir as sessões
            dos nossos utilizadores, para armazenar as suas preferências de idioma e para lhe
            fornecer anúncios relevantes.<strong> </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Os dados recolhidos são processados e armazenados informaticamente e no estrito
          cumprimento da legislação de proteção de dados pessoais sendo armazenados em base de dados
          específicas, criadas para o efeito, pela WALKING DIAGONAL LDA ou pelas entidades por esta
          subcontratadas.
        </p>
        <p>&nbsp;</p>

        <ol start={5}>
          <li>
            <strong>O que são Cookies?</strong>
          </li>
        </ol>
        <p>
          &nbsp;Cookies são pequenas etiquetas de software que são armazenadas no seu computador ou
          outros dispositivos que utilize para aceder à internet, através do navegador (browser).
        </p>
        <p>
          A WALKING DIAGONAL LDA poderá armazenar cookies no seu dispositivo para personalizar e
          facilitar ao máximo a navegação, não fornecendo esses mesmos cookies referências ou dados
          pessoais do utilizador.
        </p>
        <p>
          &nbsp;Para mais informação deverá ler a nossa Política de Cookies <em>aqui</em>.
        </p>
        <p>&nbsp;</p>

        <ol start={6}>
          <li>
            &nbsp;<strong>Como são tratados os seus dados pessoais? </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Apenas usamos os seus dados pessoais quando tal é permitido por lei, sendo nomeadamente:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>tratados de forma lícita, leal e transparente;</li>
          <li>
            recolhidos para finalidades determinadas, explícitas e legítimas, não sendo tratados
            posteriormente de uma forma incompatível com essas finalidades;
          </li>
          <li>
            adequados, pertinentes e limitados ao que é necessário relativamente às finalidades para
            as quais são tratados;
          </li>
          <li>
            exatos e, se necessário, atualizados, devendo ser tomadas as medidas adequadas para
            assegurar que sejam apagados ou retificados os dados inexatos ou incompletos, tendo em
            conta as finalidades para que foram recolhidos ou para que são tratados posteriormente;
          </li>
          <li>
            conservados de forma a permitir a identificação dos seus titulares apenas durante o
            período estritamente necessário e dentro do legalmente permitido para a prossecução das
            finalidades de recolha ou do tratamento posterior.
          </li>
        </ul>
        <p>&nbsp;</p>
        <ol start={7}>
          <li>
            <strong>
              Quais as finalidades e fundamentos do Tratamento dos Dados Pessoais e quais os prazos
              de conservação dos mesmos?
            </strong>
          </li>
        </ol>
        <p>
          Na maioria das vezes, usaremos os seus dados pessoais, que serão conservados pelo tempo
          estritamente necessário à prossecução das finalidades a que se destinam e tendo em conta
          critérios legais, de necessidade e minimização do tempo de conservação, para as seguintes
          finalidades:
        </p>
        <ol>
          <li>gestão da relação pré-contratual e contratual de prestação de serviços;</li>
          <li>
            sempre que seja necessário para os nossos interesses legítimos: (<em>i</em>) melhorar e
            controlar a qualidade dos nossos Serviços; (<em>ii</em>) fornecer-lhe uma Plataforma
            segura; e (<em>iii</em>) customizar (personalizar) produtos/artigos;
          </li>
          <li>
            cumprimento de obrigações legais ou regulamentares, incluindo perante Autoridades
            (designadamente, regulatórias ou fiscais), controlo de gestão.
          </li>
        </ol>
        <p>
          Em determinadas circunstâncias poderemos também processar os seus dados pessoais com base
          no seu consentimento. Se o fizermos, informá-lo-emos do motivo e da categoria dos dados
          pessoais a serem processados no momento em que o seu consentimento for solicitado.
        </p>
        <p>
          Abaixo estabelecemos uma descrição de como tratamos os seus dados pessoais e com que
          fundamento legal.
        </p>
        <ul>
          <li>
            <ol>
              <li>
                <strong>
                  Para efeitos de disponibilizar o acesso e prestar os Serviços através da nossa
                  Plataforma
                </strong>
              </li>
            </ol>
          </li>
          <li>
            Se iniciar sessão na APP usando o seu ID de <em>email</em>, usaremos o seu endereço de
            <em>email</em> para o identificar como utilizador e permitir que aceda à nossa App.
          </li>
          <li>
            Se entrar através da sua conta de Facebook, usaremos o primeiro e último nome do seu
            perfil de Facebook e o endereço de <em>email</em> do Facebook para o identificar como
            utilizador na nossa App e permitir que aceda à mesma.
          </li>
          <li>
            A informação de início de sessão acima referida é também usada por nós para lhe fornecer
            os nossos Serviços de acordo com os nossos
            <Link to="/termsAndConditions">
              <strong> Termos de Utilização</strong>
            </Link>
            .
          </li>
          <li>
            Usamos o seu endereço de <em>email</em> para lhe fazer sugestões e recomendações acerca
            dos nossos Serviços que possam ser do seu interesse.
          </li>
        </ul>
        <p>
          Processamos a informação acima descrita para a execução adequada do contrato celebrado
          consigo e na base do nosso interesse legítimo em executar atividades de <em>marketing</em>
          para lhe oferecer Serviços que possam ser do seu interesse.
        </p>
        <p>&nbsp;</p>

        <ol>
          <li>
            <ol start={2}>
              <li>
                <strong>Para melhorar a sua experiência na Plataforma&nbsp;</strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          i. Usamos dados de <em>clickstream</em>: (<em>i</em>) para fornecer conteúdo direcionado,
          por exemplo, disponibilizando-lhe os resultados de pesquisa mais relevantes sempre que usa
          os nossos Serviços; (<em>ii</em>) para determinar quanto tempo despende na nossa App e de
          que forma navega através da mesma, para que possamos compreender os seus interesses e
          melhorar os nossos Serviços com base nestes dados. Por exemplo, podemos fornecer-lhe
          sugestões sobre conteúdos que pode visitar com base nos conteúdos a que tenha acedido.
        </p>
        <p>
          ii. Usamos os seus dados de localização para os seguintes fins: (i) para compilar
          informação anónima e agregada acerca das caraterísticas e comportamentos dos utilizadores
          da WALKING DIAGONAL LDA, incluindo para efeitos de análise de negócios, segmentação e
          desenvolvimento de perfis anónimos; (ii) para melhorar o desempenho dos nossos Serviços e
          personalizar o conteúdo que direcionamos para si. Por exemplo - com a ajuda de dados de
          localização, exibimos listas de anúncios que se encontrem próximas de si para melhorar a
          sua experiência de compra; (iii) para medir e monitorizar a sua interação com
          <em>banners</em> publicitários de terceiros que colocamos na nossa Plataforma.
        </p>
        <p>
          iii. Com a ajuda da sua informação de início de sessão, que inclui o seu endereço de
          <em>email</em>, mapeamos os diferentes dispositivos (como computadores, telemóveis,
          <em>tablets</em>) usados por si para aceder à nossa Plataforma. Tal permite-nos associar a
          sua atividade na nossa Plataforma de modo transversal através de dispositivos e ajuda-nos
          a dar-lhe uma experiência perfeitamente integrada, independentemente do dispositivo que
          esteja a usar.
        </p>
        <p>
          Processamos a informação acima descrita tendo como fundamento de licitude para o
          referido&nbsp; tratamento o nosso interesse legítimo em melhorar a sua experiência na
          nossa Plataforma e para garantir um desempenho adequado da relação contratual estabelecida
          com os utilizadores.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <ol start={3}>
              <li>
                <strong>Para lhe fornecer uma Plataforma segura</strong>
              </li>
            </ol>
          </li>
          <li>
            Usamos os seus dados de acesso e identificadores únicos do seu dispositivo para gerir e
            proteger a nossa App (incluindo a resolução de problemas, análise de dados, testes,
            prevenção de fraude, manutenção de sistema, assistência, comunicação e armazenamento de
            dados).
          </li>
          <li>
            Analisamos as suas comunicações realizadas através da nossa funcionalidade de chat para
            prevenir fraudes e para promover a segurança, bloqueando mensagens de <em>spam</em> ou
            mensagens abusivas que lhe possam ter sido enviadas por outro utilizador.
          </li>
        </ul>
        <p>
          Processamos a informação acima descrita para um cumprimento adequado relação contratual
          estabelecida consigo, para melhorar os nossos serviços e na base dos nossos interesses
          legítimos em matéria de prevenção de fraude.
        </p>
        <p>
          Apenas conservaremos os seus dados pessoais durante o tempo estritamente necessário para
          servir os propósitos para os quais os recolhemos, incluindo para efeitos de cumprimento de
          qualquer tipo de requisitos legais, contabilísticos ou de comunicação.
        </p>
        <p>
          Para determinar o período de retenção adequado aos dados pessoais, temos em consideração o
          montante, a natureza e a sensibilidade dos dados pessoais, o risco de potencial dano
          através da divulgação ou uso não autorizados dos seus dados pessoais, os motivos pelos
          quais processamos os seus dados pessoais e se conseguimos cumprir esses motivos por outros
          meios, e os requisitos legais aplicáveis.
        </p>
        <p>
          Depois de decorrido o respetivo período de conservação, a WALKING DIAGONAL LDA eliminará
          ou anonimizará os dados pessoais do utilizador sempre que os mesmos não devam ser
          conservados para a finalidade distinta que possa subsistir.
        </p>
        <p>&nbsp;</p>
        <ol start={8}>
          <li>
            <strong>Com quem partilhamos os seus dados?</strong>
          </li>
        </ol>
        <p>
          Os dados recolhidos e detidos pela WALKING DIAGONAL LDA poderão ser transmitidos, com
          respeito pelo dever da confidencialidade e pelo princípio da finalidade que presidiu à sua
          recolha, para as seguintes entidades:
        </p>
        <p>
          <strong>Subcontratantes</strong> que procederão ao tratamento dos dados por conta da
          Empresa e de acordo com as finalidades por esta determinadas, designadamente, fornecedores
          de serviços de terceiros (serviços de armazenamento "<em>cloud</em>", como a
          <em>Amazon Web Services</em> e a <em>Microsoft Azure).</em>
        </p>
        <p>
          <strong>Autoridades judiciais ou administrativas</strong>, nos casos em que tal cedência
          seja obrigatória.
        </p>
        <p>&nbsp;</p>
        <ol start={9}>
          <li>
            <strong>Quais são os seus direitos?</strong>
          </li>
        </ol>
        <p>
          Nos termos da Lei de Proteção de Dados Pessoais, garantimos-lhe o direito de acesso,
          atualização, retificação, eliminação, limitação de tratamento, portabilidade e apagamento
          dos seus dados pessoais.
        </p>
        <p>
          Conferimos-lhe também o direito de oposição à utilização dos dados facultados para fins de
          marketing, para o envio de comunicações informativas ou de inclusão em listas ou serviços
          informativos. Em alguns casos, poderemos demonstrar que temos razões legítimas e
          imperiosas que se sobreponham aos seus direitos e garantias para processar a sua
          informação.
        </p>
        <p>
          Não obstante, a retirada de consentimento não afeta a legalidade de qualquer processamento
          que já tenhamos levado a cabo com base no consentimento previamente dado.
        </p>
        <p>
          Como procedemos a tratamento de dados tendo por fundamento o interesse legítimo, poderá
          opor-se ao tratamento dos mesmos, e este cessará desde que os interesses que invoque
          prevaleçam sobre as nossas razões para o tratamento.
        </p>
        <p>
          O exercício destes direitos deverá ser realizado através dos canais acima referidos no
          ponto 2.
        </p>
        <p>Poderá ainda apresentar uma reclamação à autoridade de controlo.&nbsp;</p>
        <p>&nbsp;</p>

        <ol start={10}>
          <li>
            <strong>Como posso gerir as comunicações de marketing que recebo?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Pode solicitar, a qualquer momento, que deixemos de lhe enviar comunicações de marketing,
          bastando, para tal, aceder ao link de recusa [<em>opt-out</em>] na mensagem de email que
          lhe tenha sido enviada ou alterando as suas definições de comunicação na sua conta. Caso
          tenha problemas em alterar as suas definições, queira por favor contactar-nos através do
          nosso […].
        </p>
        <p>
          Poderá receber comunicações de marketing da nossa parte se tiver solicitado tal informação
          e se usar a nossa Plataforma.
        </p>
        <p>&nbsp;</p>

        <ol start={11}>
          <li>
            <strong>Os seus dados são tratados de forma segura?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          A WALKING DIAGONAL LDA adota medidas técnicas e organizativas adequadas para proteger os
          Dados Pessoais contra a sua perda, destruição ou danificação, acidental ou ilícita e, bem
          assim, para assegurar que os dados facultados sejam protegidos contra o acesso ou
          utilização por parte de terceiros não autorizados. A WALKING DIAGONAL LDA exige dos seus
          subcontratantes a adoção de medidas de segurança equivalentes àquelas que pratica.
        </p>
        <p>
          Apesar das medidas que implementámos para proteger os seus dados, a transferência de dados
          através da <em>Internet</em> ou outras redes abertas nunca é completamente segura,
          existindo um risco de os seus dados pessoais serem acedidos por terceiros não autorizados.
        </p>
        <p>
          Não obstante as medidas de segurança praticadas pela WALKING DIAGONAL LDA, o utilizador
          deve adotar medidas adicionais de segurança designadamente, assegurar que utiliza
          equipamentos e um Browser atualizados em termos de segurança adequadamente configurados,
          com firewall ativa, antivírus e anti-spyware e, certificar-se da autenticidade dos sites
          que visita na internet, devendo evitar websites em cuja reputação não confie.
        </p>
        <p>&nbsp;</p>
        <ol start={12}>
          <li>
            <strong>
              Links para <em>websites</em> de terceiros
            </strong>
          </li>
        </ol>
        <p>
          A App da WALKING DIAGONAL LDA pode conter links para websites ou aplicações de terceiros.
          Se aceder a um desses links, queira por favor ter em conta que cada um terá a sua própria
          política de privacidade. Não controlamos esses <em>websites</em>/aplicações e não somos
          responsáveis por essas políticas. Ao sair da nossa Plataforma, recomendamos que leia a
          declaração de privacidade de todos os <em>websites</em> que visita.<a>&nbsp;</a>
        </p>
        <p>&nbsp;</p>

        <ol start={13}>
          <li>
            <strong>Transferência dos Dados</strong>
          </li>
        </ol>
        <p>
          Caso possam ocorrer transferências de dados para países terceiros que não pertençam à
          União Europeia ou ao Espaço Económico Europeu, a WALKING DIAGONAL LDA cumprirá com a lei,
          nomeadamente no que respeita à adequabilidade do país de destino no que respeita a
          proteção de dados pessoais e aos requisitos que são aplicáveis a estas transferências, não
          sendo transferidos dados pessoais para jurisdições que não ofereçam garantias de segurança
          e proteção.
        </p>
        <p>&nbsp;</p>

        <ol start={14}>
          <li>
            <strong>Alterações à Política de Privacidade</strong>
          </li>
        </ol>
        <p>
          A WALKING DIAGONAL LDA reserva-se o direito de, a qualquer altura, proceder a
          reajustamentos ou alterações à presente Política de Privacidade, sendo essas alterações
          devidamente publicitadas neste site.
        </p>
      </div>
      <div className="">
        <p>
          A sua privacidade e a protecção dos seus dados pessoais são pilares essenciais para a
          WALKING DIAGONAL LDA. Esta declaração de privacidade irá informá-lo sobre a forma como
          usamos os seus dados pessoais, os direitos de que goza e os mecanismos legais que tem ao
          seu dispor. Queira por favor ler atentamente esta declaração de privacidade antes de
          utilizar os nossos Serviços.
        </p>
        <p>Nesta Política de Privacidade:</p>
        <p>
          <strong>Serviços</strong> refere-se a quaisquer produtos, serviços, conteúdos,
          funcionalidades, tecnologias ou funções e todos os websites, aplicações e serviços
          relacionados que lhe são oferecidos por nós.
        </p>
        <p>
          <strong>Plataforma</strong> refere-se ao website e aplicação móvel através dos quais lhe
          oferecemos os nossos Serviços.
        </p>
        <ol>
          <li>
            <strong>Quem é o responsável pela recolha e tratamento dos dados?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          A WALKING DIAGONAL LDA é a entidade responsável pelo tratamento dos seus dados pessoais,
          fornecidos pelo respetivo titular, bem como dos demais dados pessoais recolhidos em
          qualquer outro suporte, incluindo os respeitantes à utilização dos Serviços prestados pela
          WALKING DIAGONAL LDA e os resultantes da utilização da APP ou simuladores disponibilizados
          para pesquisa, marcação e gestão dos serviços e informação gerada com base nessa
          utilização (doravante "<strong>Dados Pessoais</strong>"), os quais serão tratados para as
          finalidades enumeradas no ponto 4, abaixo.
        </p>
        <p>
          A WALKING DIAGONAL LDA … é uma sociedade de responsabilidade […]. com o número único de
          matrícula e de pessoa coletiva, 515901105, com sede na Rua de São Bernardo nº22, 1200-825
          Lisboa.
        </p>
        <p>&nbsp;</p>

        <ol start={2}>
          <li>
            <strong>Quais os contactos do responsável pelo tratamento? </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          O Responsável pelo Tratamento de Dados Pessoais pode ser contactado (designadamente para
          qualquer informação adicional ou para exercer os seus direitos), por escrito, para os
          seguintes endereços:
        </p>
        <p>&nbsp;</p>
        <p>- [Rua de São Bernardo nº22, 1200-825 Lisboa];</p>
        <p>- [info@suopapp.com]; ou </p>
        <p>- Secção de ajuda da nossa Aplicação.</p>
        <p>&nbsp;</p>
        <ol start={3}>
          <li>
            <strong>&nbsp;</strong>
            <strong>O que são dados pessoais?</strong>
          </li>
        </ol>
        <p>
          Dados pessoais são qualquer informação, de qualquer natureza e independentemente do
          respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou
          identificável. É considerada identificável a pessoa que possa ser identificada direta ou
          indiretamente, designadamente por referência a um número de identificação ou a um ou mais
          elementos específicos da sua identidade física, fisiológica, psíquica, económica, cultural
          ou social.
        </p>
        <p>&nbsp;</p>
        <ol start={4}>
          <li>
            <strong>Que dados pessoais são recolhidos e tratados?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            <ol>
              <li>
                <strong>Dados fornecidos por interação direta </strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>- Registo e outras informações de conta</strong>
        </p>
        <p>
          Se decidir subscrever os nossos Serviços, em concreto o nosso <em>website </em>e App,
          ser-lhe-á pedido que adicione o seu <strong>produto/artigo</strong>, que é um elemento
          obrigatório do anúncio. Ao disponibilizar o seu produto/artigo deverá adicionar os
          seguintes dados que serão acessíveis ao público: uma fotografia do mesmo, o título do
          artigo, uma descrição e uma categoria. Deverá igualmente atribuir um valor monetário ao
          produto/artigo ao qual apenas a WALKING DIAGONAL LDA terá acesso. Sem estes dados, não
          conseguiremos prestar os Serviços.
        </p>
        <p>
          Quando se regista na nossa App, ser-lhe-á pedido que forneça, obrigatoriamente, o seu
          endereço de email. Em adição e facultativamente, poderá também fornecer alguns dados que
          serão de acesso público, a saber: o seu nome, apelido, a sua cidade, uma descrição que
          constará do seu perfil (<em>Nickname</em>) e uma fotografia de perfil. Em adição,
          ser-lhe-ão solicitados o seu NIF, data de nascimento e número de telemóvel, dados esses a
          que apenas a WALKING DIAGONAL LDA terá acesso, para efeitos de segmentação.
        </p>
        <p>
          Em alternativa a este registo, o utilizador poderá registar-se via Facebook, passando a
          WALKING DIAGONAL LDA a tratar os dados pessoais do utilizador, que por essa via lhe forem
          transmitidos.
        </p>
        <p>
          <strong>- Comunicação através da funcionalidade de chat na nossa Plataforma</strong>
        </p>
        <p>
          Ao usar a nossa funcionalidade de <em>chat</em> para comunicar com outros utilizadores,
          recolhemos a informação que escolha fornecer a outros utilizadores através da mesma. Todas
          as mensagens trocadas entre utilizadores são mantidas numa base de dados, com elevados
          níveis de segurança, incluindo encriptação, de acesso altamente restrito (se e quando
          necessário), por forma a melhor proteger a utilização da Plataforma por parte dos seus
          utilizadores, designadamente para a filtragem e apreensão de mensagens de <em>spam</em> e
          prevenção de ataques aos sistemas de suporte.&nbsp;
        </p>
        <p>&nbsp;</p>

        <ol>
          <li>
            <ol start={2}>
              <li>
                <strong>Dados que recolhemos automaticamente quando usa os nossos Serviços</strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Quando interage com a nossa App, ou usa os nossos Serviços, recolhemos automaticamente as
          seguintes informações sobre si:
        </p>
        <ol>
          <li>
            <strong>Informações sobre o seu dispositivo: </strong>como por exemplo, a versão de
            sistema operativo ou identificadores únicos, associando esse dispositivo aos
            identificadores de dispositivo da sua conta WALKING DIAGONAL LDA.
          </li>
          <li>
            <strong>Informações de localização: </strong>dependendo das permissões no seu
            dispositivo, se publicar um artigo no nosso <em>website</em>, iremos recolher e
            processar automaticamente a informação acerca da sua localização atual através de
            endereço de IP, GPS, pontos de acesso <em>Wi-fi</em> e torres de dados móveis.
          </li>
          <li>
            <strong>Dados de utilizador e de Registo:</strong> detalhes técnicos, incluindo o
            Protocolo de Internet (IP) do seu dispositivo, fuso horário e sistema operativo. Também
            iremos armazenar a sua informação de início de sessão (data de registo, data da última
            alteração de palavra-passe, data do último início de sessão realizado com sucesso), o
            tipo e a versão do seu navegador.
          </li>
          <li>
            <strong>Dados de clickstream:</strong> recolhemos informação acerca da sua atividade na
            nossa App, incluindo os <em>websites</em> que usou para aceder à nossa Plataforma,
            registo de data e hora de cada visita, pesquisas realizadas, listagens ou
            <em>banners</em> de publicidade a que tenha acedido, a sua interação com esses anúncios
            de publicidade ou listagens, duração da sua visita e a ordem em que acedeu ao conteúdo
            na nossa App.
          </li>
          <li>
            <strong>Cookies e Tecnologias Similares:</strong> usamos cookies para gerir as sessões
            dos nossos utilizadores, para armazenar as suas preferências de idioma e para lhe
            fornecer anúncios relevantes.<strong> </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Os dados recolhidos são processados e armazenados informaticamente e no estrito
          cumprimento da legislação de proteção de dados pessoais sendo armazenados em base de dados
          específicas, criadas para o efeito, pela WALKING DIAGONAL LDA ou pelas entidades por esta
          subcontratadas.
        </p>
        <p>&nbsp;</p>

        <ol start={5}>
          <li>
            <strong>O que são Cookies?</strong>
          </li>
        </ol>
        <p>
          &nbsp;Cookies são pequenas etiquetas de software que são armazenadas no seu computador ou
          outros dispositivos que utilize para aceder à internet, através do navegador (browser).
        </p>
        <p>
          A WALKING DIAGONAL LDA poderá armazenar cookies no seu dispositivo para personalizar e
          facilitar ao máximo a navegação, não fornecendo esses mesmos cookies referências ou dados
          pessoais do utilizador.
        </p>
        <p>
          &nbsp;Para mais informação deverá ler a nossa Política de Cookies <em>aqui</em>.
        </p>
        <p>&nbsp;</p>

        <ol start={6}>
          <li>
            &nbsp;<strong>Como são tratados os seus dados pessoais? </strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Apenas usamos os seus dados pessoais quando tal é permitido por lei, sendo nomeadamente:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>tratados de forma lícita, leal e transparente;</li>
          <li>
            recolhidos para finalidades determinadas, explícitas e legítimas, não sendo tratados
            posteriormente de uma forma incompatível com essas finalidades;
          </li>
          <li>
            adequados, pertinentes e limitados ao que é necessário relativamente às finalidades para
            as quais são tratados;
          </li>
          <li>
            exatos e, se necessário, atualizados, devendo ser tomadas as medidas adequadas para
            assegurar que sejam apagados ou retificados os dados inexatos ou incompletos, tendo em
            conta as finalidades para que foram recolhidos ou para que são tratados posteriormente;
          </li>
          <li>
            conservados de forma a permitir a identificação dos seus titulares apenas durante o
            período estritamente necessário e dentro do legalmente permitido para a prossecução das
            finalidades de recolha ou do tratamento posterior.
          </li>
        </ul>
        <p>&nbsp;</p>
        <ol start={7}>
          <li>
            <strong>
              Quais as finalidades e fundamentos do Tratamento dos Dados Pessoais e quais os prazos
              de conservação dos mesmos?
            </strong>
          </li>
        </ol>
        <p>
          Na maioria das vezes, usaremos os seus dados pessoais, que serão conservados pelo tempo
          estritamente necessário à prossecução das finalidades a que se destinam e tendo em conta
          critérios legais, de necessidade e minimização do tempo de conservação, para as seguintes
          finalidades:
        </p>
        <ol>
          <li>gestão da relação pré-contratual e contratual de prestação de serviços;</li>
          <li>
            sempre que seja necessário para os nossos interesses legítimos: (<em>i</em>) melhorar e
            controlar a qualidade dos nossos Serviços; (<em>ii</em>) fornecer-lhe uma Plataforma
            segura; e (<em>iii</em>) customizar (personalizar) produtos/artigos;
          </li>
          <li>
            cumprimento de obrigações legais ou regulamentares, incluindo perante Autoridades
            (designadamente, regulatórias ou fiscais), controlo de gestão.
          </li>
        </ol>
        <p>
          Em determinadas circunstâncias poderemos também processar os seus dados pessoais com base
          no seu consentimento. Se o fizermos, informá-lo-emos do motivo e da categoria dos dados
          pessoais a serem processados no momento em que o seu consentimento for solicitado.
        </p>
        <p>
          Abaixo estabelecemos uma descrição de como tratamos os seus dados pessoais e com que
          fundamento legal.
        </p>
        <ul>
          <li>
            <ol>
              <li>
                <strong>
                  Para efeitos de disponibilizar o acesso e prestar os Serviços através da nossa
                  Plataforma
                </strong>
              </li>
            </ol>
          </li>
          <li>
            Se iniciar sessão na APP usando o seu ID de <em>email</em>, usaremos o seu endereço de
            <em>email</em> para o identificar como utilizador e permitir que aceda à nossa App.
          </li>
          <li>
            Se entrar através da sua conta de Facebook, usaremos o primeiro e último nome do seu
            perfil de Facebook e o endereço de <em>email</em> do Facebook para o identificar como
            utilizador na nossa App e permitir que aceda à mesma.
          </li>
          <li>
            A informação de início de sessão acima referida é também usada por nós para lhe fornecer
            os nossos Serviços de acordo com os nossos
            <Link to="/termsAndConditions">
              <strong> Termos de Utilização</strong>
            </Link>
            .
          </li>
          <li>
            Usamos o seu endereço de <em>email</em> para lhe fazer sugestões e recomendações acerca
            dos nossos Serviços que possam ser do seu interesse.
          </li>
        </ul>
        <p>
          Processamos a informação acima descrita para a execução adequada do contrato celebrado
          consigo e na base do nosso interesse legítimo em executar atividades de <em>marketing</em>
          para lhe oferecer Serviços que possam ser do seu interesse.
        </p>
        <p>&nbsp;</p>

        <ol>
          <li>
            <ol start={2}>
              <li>
                <strong>Para melhorar a sua experiência na Plataforma&nbsp;</strong>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          i. Usamos dados de <em>clickstream</em>: (<em>i</em>) para fornecer conteúdo direcionado,
          por exemplo, disponibilizando-lhe os resultados de pesquisa mais relevantes sempre que usa
          os nossos Serviços; (<em>ii</em>) para determinar quanto tempo despende na nossa App e de
          que forma navega através da mesma, para que possamos compreender os seus interesses e
          melhorar os nossos Serviços com base nestes dados. Por exemplo, podemos fornecer-lhe
          sugestões sobre conteúdos que pode visitar com base nos conteúdos a que tenha acedido.
        </p>
        <p>
          ii. Usamos os seus dados de localização para os seguintes fins: (i) para compilar
          informação anónima e agregada acerca das caraterísticas e comportamentos dos utilizadores
          da WALKING DIAGONAL LDA, incluindo para efeitos de análise de negócios, segmentação e
          desenvolvimento de perfis anónimos; (ii) para melhorar o desempenho dos nossos Serviços e
          personalizar o conteúdo que direcionamos para si. Por exemplo - com a ajuda de dados de
          localização, exibimos listas de anúncios que se encontrem próximas de si para melhorar a
          sua experiência de compra; (iii) para medir e monitorizar a sua interação com
          <em>banners</em> publicitários de terceiros que colocamos na nossa Plataforma.
        </p>
        <p>
          iii. Com a ajuda da sua informação de início de sessão, que inclui o seu endereço de
          <em>email</em>, mapeamos os diferentes dispositivos (como computadores, telemóveis,
          <em>tablets</em>) usados por si para aceder à nossa Plataforma. Tal permite-nos associar a
          sua atividade na nossa Plataforma de modo transversal através de dispositivos e ajuda-nos
          a dar-lhe uma experiência perfeitamente integrada, independentemente do dispositivo que
          esteja a usar.
        </p>
        <p>
          Processamos a informação acima descrita tendo como fundamento de licitude para o
          referido&nbsp; tratamento o nosso interesse legítimo em melhorar a sua experiência na
          nossa Plataforma e para garantir um desempenho adequado da relação contratual estabelecida
          com os utilizadores.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <ol start={3}>
              <li>
                <strong>Para lhe fornecer uma Plataforma segura</strong>
              </li>
            </ol>
          </li>
          <li>
            Usamos os seus dados de acesso e identificadores únicos do seu dispositivo para gerir e
            proteger a nossa App (incluindo a resolução de problemas, análise de dados, testes,
            prevenção de fraude, manutenção de sistema, assistência, comunicação e armazenamento de
            dados).
          </li>
          <li>
            Analisamos as suas comunicações realizadas através da nossa funcionalidade de chat para
            prevenir fraudes e para promover a segurança, bloqueando mensagens de <em>spam</em> ou
            mensagens abusivas que lhe possam ter sido enviadas por outro utilizador.
          </li>
        </ul>
        <p>
          Processamos a informação acima descrita para um cumprimento adequado relação contratual
          estabelecida consigo, para melhorar os nossos serviços e na base dos nossos interesses
          legítimos em matéria de prevenção de fraude.
        </p>
        <p>
          Apenas conservaremos os seus dados pessoais durante o tempo estritamente necessário para
          servir os propósitos para os quais os recolhemos, incluindo para efeitos de cumprimento de
          qualquer tipo de requisitos legais, contabilísticos ou de comunicação.
        </p>
        <p>
          Para determinar o período de retenção adequado aos dados pessoais, temos em consideração o
          montante, a natureza e a sensibilidade dos dados pessoais, o risco de potencial dano
          através da divulgação ou uso não autorizados dos seus dados pessoais, os motivos pelos
          quais processamos os seus dados pessoais e se conseguimos cumprir esses motivos por outros
          meios, e os requisitos legais aplicáveis.
        </p>
        <p>
          Depois de decorrido o respetivo período de conservação, a WALKING DIAGONAL LDA eliminará
          ou anonimizará os dados pessoais do utilizador sempre que os mesmos não devam ser
          conservados para a finalidade distinta que possa subsistir.
        </p>
        <p>&nbsp;</p>
        <ol start={8}>
          <li>
            <strong>Com quem partilhamos os seus dados?</strong>
          </li>
        </ol>
        <p>
          Os dados recolhidos e detidos pela WALKING DIAGONAL LDA poderão ser transmitidos, com
          respeito pelo dever da confidencialidade e pelo princípio da finalidade que presidiu à sua
          recolha, para as seguintes entidades:
        </p>
        <p>
          <strong>Subcontratantes</strong> que procederão ao tratamento dos dados por conta da
          Empresa e de acordo com as finalidades por esta determinadas, designadamente, fornecedores
          de serviços de terceiros (serviços de armazenamento "<em>cloud</em>", como a
          <em>Amazon Web Services</em> e a <em>Microsoft Azure).</em>
        </p>
        <p>
          <strong>Autoridades judiciais ou administrativas</strong>, nos casos em que tal cedência
          seja obrigatória.
        </p>
        <p>&nbsp;</p>
        <ol start={9}>
          <li>
            <strong>Quais são os seus direitos?</strong>
          </li>
        </ol>
        <p>
          Nos termos da Lei de Proteção de Dados Pessoais, garantimos-lhe o direito de acesso,
          atualização, retificação, eliminação, limitação de tratamento, portabilidade e apagamento
          dos seus dados pessoais.
        </p>
        <p>
          Conferimos-lhe também o direito de oposição à utilização dos dados facultados para fins de
          marketing, para o envio de comunicações informativas ou de inclusão em listas ou serviços
          informativos. Em alguns casos, poderemos demonstrar que temos razões legítimas e
          imperiosas que se sobreponham aos seus direitos e garantias para processar a sua
          informação.
        </p>
        <p>
          Não obstante, a retirada de consentimento não afeta a legalidade de qualquer processamento
          que já tenhamos levado a cabo com base no consentimento previamente dado.
        </p>
        <p>
          Como procedemos a tratamento de dados tendo por fundamento o interesse legítimo, poderá
          opor-se ao tratamento dos mesmos, e este cessará desde que os interesses que invoque
          prevaleçam sobre as nossas razões para o tratamento.
        </p>
        <p>
          O exercício destes direitos deverá ser realizado através dos canais acima referidos no
          ponto 2.
        </p>
        <p>Poderá ainda apresentar uma reclamação à autoridade de controlo.&nbsp;</p>
        <p>&nbsp;</p>

        <ol start={10}>
          <li>
            <strong>Como posso gerir as comunicações de marketing que recebo?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Pode solicitar, a qualquer momento, que deixemos de lhe enviar comunicações de marketing,
          bastando, para tal, aceder ao link de recusa [<em>opt-out</em>] na mensagem de email que
          lhe tenha sido enviada ou alterando as suas definições de comunicação na sua conta. Caso
          tenha problemas em alterar as suas definições, queira por favor contactar-nos através do
          nosso […].
        </p>
        <p>
          Poderá receber comunicações de marketing da nossa parte se tiver solicitado tal informação
          e se usar a nossa Plataforma.
        </p>
        <p>&nbsp;</p>

        <ol start={11}>
          <li>
            <strong>Os seus dados são tratados de forma segura?</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          A WALKING DIAGONAL LDA adota medidas técnicas e organizativas adequadas para proteger os
          Dados Pessoais contra a sua perda, destruição ou danificação, acidental ou ilícita e, bem
          assim, para assegurar que os dados facultados sejam protegidos contra o acesso ou
          utilização por parte de terceiros não autorizados. A WALKING DIAGONAL LDA exige dos seus
          subcontratantes a adoção de medidas de segurança equivalentes àquelas que pratica.
        </p>
        <p>
          Apesar das medidas que implementámos para proteger os seus dados, a transferência de dados
          através da <em>Internet</em> ou outras redes abertas nunca é completamente segura,
          existindo um risco de os seus dados pessoais serem acedidos por terceiros não autorizados.
        </p>
        <p>
          Não obstante as medidas de segurança praticadas pela WALKING DIAGONAL LDA, o utilizador
          deve adotar medidas adicionais de segurança designadamente, assegurar que utiliza
          equipamentos e um Browser atualizados em termos de segurança adequadamente configurados,
          com firewall ativa, antivírus e anti-spyware e, certificar-se da autenticidade dos sites
          que visita na internet, devendo evitar websites em cuja reputação não confie.
        </p>
        <p>&nbsp;</p>
        <ol start={12}>
          <li>
            <strong>
              Links para <em>websites</em> de terceiros
            </strong>
          </li>
        </ol>
        <p>
          A App da WALKING DIAGONAL LDA pode conter links para websites ou aplicações de terceiros.
          Se aceder a um desses links, queira por favor ter em conta que cada um terá a sua própria
          política de privacidade. Não controlamos esses <em>websites</em>/aplicações e não somos
          responsáveis por essas políticas. Ao sair da nossa Plataforma, recomendamos que leia a
          declaração de privacidade de todos os <em>websites</em> que visita.<a>&nbsp;</a>
        </p>
        <p>&nbsp;</p>

        <ol start={13}>
          <li>
            <strong>Transferência dos Dados</strong>
          </li>
        </ol>
        <p>
          Caso possam ocorrer transferências de dados para países terceiros que não pertençam à
          União Europeia ou ao Espaço Económico Europeu, a WALKING DIAGONAL LDA cumprirá com a lei,
          nomeadamente no que respeita à adequabilidade do país de destino no que respeita a
          proteção de dados pessoais e aos requisitos que são aplicáveis a estas transferências, não
          sendo transferidos dados pessoais para jurisdições que não ofereçam garantias de segurança
          e proteção.
        </p>
        <p>&nbsp;</p>

        <ol start={14}>
          <li>
            <strong>Alterações à Política de Privacidade</strong>
          </li>
        </ol>
        <p>
          A WALKING DIAGONAL LDA reserva-se o direito de, a qualquer altura, proceder a
          reajustamentos ou alterações à presente Política de Privacidade, sendo essas alterações
          devidamente publicitadas neste site.
        </p>
      </div>
    </Layout>
  );
};

export default privacyPolicy;
