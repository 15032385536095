import React from 'react';
import { Link } from 'gatsby';
import Footer from './Footer';
import styles from './Layout.module.scss';

import logo from '../images/logo.svg';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <Link to="/" className={styles.logoContainer}>
            <img className={styles.logo} src={logo} alt="Suop Logo" />
          </Link>
        </div>
      </div>
      <main className={styles.content}>{children}</main>
      <Footer></Footer>
    </>
  );
};

export default Layout;
