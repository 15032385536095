import React from 'react';
import { Link } from 'gatsby';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import styles from './Footer.module.scss';
import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.svg';

import logo from '../images/logo.svg';
const Footer = (): React.ReactElement => {
  return (
    <>
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <a href="/">
              <img src={logo} alt=" " />
            </a>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.termsContainer}>
              <h4 className={styles.footerTitle}>Documentação</h4>
              <div className={styles.documentationContainer}>
                <div className={styles.documentationContent}>
                  <Link to="/termsAndConditions" className={styles.pageLink}>
                    Termos e Condições
                  </Link>
                  <Link to="/privacyPolicy" className={styles.pageLink}>
                    Política de Privacidade
                  </Link>
                </div>
                <div className={styles.documentationContent}>
                  <Link to="/cookiePolicy" className={styles.pageLink}>
                    Política de Cookies
                  </Link>
                  <Link to="/faqs" className={styles.pageLink}>
                    FAQs
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.socialMediaContainer}>
              <h4 className={styles.footerTitle}>Redes Sociais</h4>
              <div className={styles.socialMediaIconsContainer}>
                <a
                  className={styles.socialMediaLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/suopapp"
                >
                  <FaFacebook className={styles.icon}></FaFacebook>
                </a>
                <a
                  className={styles.socialMediaLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/suop_app/"
                >
                  <FaInstagram className={styles.icon}></FaInstagram>
                </a>
                <a
                  className={styles.socialMediaLink}
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/Suop_app"
                >
                  <FaTwitter className={styles.icon}></FaTwitter>
                </a>
              </div>
            </div>
            {/* <div className={styles.downloadContainer}>
              <h4 className={styles.footerTitle}>Descarrega já!</h4>
              
            </div> */}
          </div>
        </div>
      </div>
      <div className={styles.belowFooter}>
        <div className={styles.copyrightContainer}>
          <span>
            © Copyright © 2021 Walking Diagonal, LDA | Todos&nbsp;os&nbsp;direitos&nbsp;reservados
          </span>
          <span>
            Powered by{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://moscadigital.pt/?utm_source=suopapp.com"
              className={styles.moscaLink}
            >
              Mosca Digital
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
